



















import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import { statuses } from "@/constants/paymentTransactions";
import makeSelectOptions from "@common/util/makeSelectOptions";
import PointInputVue from "./partials/payment-transactions/PointInput.vue";
import PaymentMethodInputVue from "./partials/payment-transactions/PaymentMethodInput.vue";
import Loading from "@/components/Loading.vue";
import BlurredTenant from "@/components/BlurredTenant.vue";

export default Vue.extend({
  components: { Loading, BlurredTenant },
  data() {
    return {
      showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
      self: this,
      userManager,
      loading: false,
      tenantSetting: {},
      point: 0,
      addPointBtnOptions: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "time",
          "sort-desc": true,
        },
        content: {
          name: "Payment Transactions",
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              tenantId: userManager.checkRole(["systemAdmin", "admin"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Organization",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("tenants", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            orderBy: "name",
                            orderType: "asc",
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: {
                  type: "XAutocomplete",
                  attrs: {
                      label: "User",
                      required: true,
                      "item-value": "_id",
                      "item-text": "email",
                      xOptions: {
                          content: {
                              async itemsSuggestor(search, value, limit) {
                                  const { items } = await coreApiClient.call("users", "findAllUser", {
                                      payload: JSON.stringify({
                                          limit,
                                          search,
                                          orderBy: "email",
                                          orderType: "asc",
                                          filters: [
                                              {
                                                  key: "_id",
                                                  operator: "equal_to",
                                                  value,
                                              },
                                          ],
                                          extraParams: {
                                              searchCrossTenant: !!search && true
                                          }
                                      }),
                                  });
                                  return items;
                              },
                          },
                      },
                  },
                  rules: {
                      equal_to: {},
                  },
              },
              status: {
                type: "selectMultiple",
                attrs: {
                  label: "Status",
                  items: makeSelectOptions(statuses),
                },
                ext: {
                  defaultValue: ["success"],
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            time: {
              text: "Paid Time",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            amount: {
              text: "Amount",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            point: {
              text: "After Discount",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            paymentMethod: {
              text: "Payment Method",
              sortable: true,
              options: {
                transform: (value) => value.name,
                label: true,
              },
            },
            transactionRef: {
              text: "Transaction Ref",
              options: {
                subProp: "details.vnp_BankTranNo",
              },
            },
            status: {
              text: "Status",
              sortable: true,
              hidden: true,
              options: {
                transform: (value) => statuses[value],
                label: true,
              },
            },
            errorCode: {
              text: "Error Code",
              sortable: true,
              hidden: true,
            },
            errorMessage: {
              text: "Error Message",
              sortable: true,
              hidden: true,
            },
            user: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor"]) && {
              text: "User",
              options: {
                subProp: "user.email",
                label: true,
                sortBy: "userId",
              },
            },
            tenant: userManager.checkRole(["systemAdmin", "admin"]) && {
              text: "Organization",
              options: {
                subProp: "tenant.name",
                label: true,
                sortBy: "tenantId",
              },
            },
            action: {
              text: "Actions",
              hidden: true,
            },
          },
          topActionButtons: {
            export: userManager.checkRole([
              "systemAdmin",
              "admin",
              "cpoAdmin", "cpoMonitor",
              "retailer",
            ]) && {
              content: {
                text: "Export all",
                icon: "mdi-microsoft-excel",
              },
              on: {
                  xClick({ self }) {
                    const dataTable = self.context();
                    coreApiClient.download(
                      "paymentTransactions",
                      `@/export`,
                      dataTable.getFindAllOptions()
                    );
                  },
              },
            },
          },
          findAllOptionsFilter(options) {
            if (userManager.checkRole(["user"], true)) {
              options.filters.push({
                key: "status",
                operator: "equal_to",
                value: "success",
              });
            }
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("paymentTransactions", "findAll", options);
              return [items, count];
            },
          },
        },
      },
    };
  },
  methods: {
    makeAddPointBtnOptions() {
      return {
        attrs: {
          color: "primary",
          dark: false,
          small: true,
          class: "ml-4",
          height: "30px",
        },
        content: {
          text: "Top-up",
        },
        target: {
          dialog: {
            handlers: {
              initialize() {
                this.options.content.buttons.save.states.enabled = new DataContainer(false);
              },
            },
            attrs: {
              width: "700px",
            },
            content: {
              toolbar: {
                title: "Top-up",
              },
              content: {
                type: "XForm",
                makeAttrs(attrs, dialog) {
                  attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                  attrs.xContext = dialog;
                  attrs.xData = new DataContainer({});
                  return attrs;
                },
                attrs: {
                  xOptions: {
                    content: {
                      sections: {
                        default: {
                          fields: {
                            point: {
                              type: PointInputVue,
                              attrs: {
                                label: "Amount",
                                hideLabel: true,
                                required: true,
                                items: [50000, 100000, 200000, 500000, 1000000, 2000000],
                                tenantSetting: this.tenantSetting,
                              },
                              ext: {
                                defaultValue: 50000,
                              },
                            },
                            paymentMethod: {
                              type: PaymentMethodInputVue,
                              attrs: {
                                label: "Payment Method",
                                hideLabel: true,
                                required: true,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              buttons: {
                cancel: {
                  ext: {
                    hidden: true,
                  },
                },
                save: {
                  attrs: {
                    block: true,
                    text: false,
                  },
                  content: {
                    text: "Pay",
                  },
                  states: {},
                  on: {
                    async xClick({ self }) {
                      const dialog = self.context();
                      // const { tenantSetting } = dialog.context().context();
                      const form = dialog.contentContainer.value;
                      const data = form.getData();
                      let apiName = "";
                      switch (data.paymentMethod.type) {
                        case "vnpay":
                          apiName = "integrationVnpay";
                          break;
                        case "momo":
                          apiName = "integrationMomo";
                          break;
                        default:
                          apiName = "integrationVnpay";
                          break;
                      }

                      const amount = data.point;

                      self.loading.value = true;
                      const result = await coreApiClient.call(apiName, "createPaymentUrl", {
                        amount,
                        id : data.paymentMethod.id,
                        device : "web",
                      });
                      self.loading.value = false;
                      if (result) {
                        location.href = result;
                      }
                    },
                  },
                },
              },
            },
          },
        },
      };
    },
  },
  async created() {
    if (userManager.checkRole(["user"], true)) {
      this.loading = true;
      {
        const { point } = await coreApiClient.call("users", "findById", {
          id: userManager.getUserInfo()._id,
        });
        this.point = point || 0;
      }
      {
        this.tenantSetting = await coreApiClient.call("tenants", "getSetting", {
          id: userManager.getUserInfo().tenant._id,
        });
      }
      this.addPointBtnOptions = this.makeAddPointBtnOptions();
      this.loading = false;
    }
  },
});
